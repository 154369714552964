import {
  SAVE_CHAT,
  SAVE_CHAT_SUCCESS,
  SAVE_CHAT_FAILURE,
  FETCH_CHATS,
  FETCH_CHATS_SUCCESS,
  FETCH_CHATS_FAILURE,
  RESET_CHAT_SAVED,
  FETCH_CHAT_BY_ID,
  FETCH_CHAT_BY_ID_SUCCESS,
  FETCH_CHAT_BY_ID_FAILURE,
  FETCH_AGENTS,
  FETCH_AGENTS_SUCCESS,
  FETCH_AGENTS_FAILURE,
  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  ChatActionTypes,
  ChatData,
  AgentData,
} from "../actions/chatActions";

export interface ChatState {
  saving: boolean;
  fetching: boolean; // New field to track fetching state
  saved: boolean;
  error: string | null;
  data: ChatData | null;
  presaved: ChatData | null;
  chats: ChatData[] | null; // New field to store fetched chats
  agents: AgentData[] | null;
  sending: boolean;
  lastSentMessage: any | null;
  errorCode?: number | null | undefined;
  total_count?: number | null;
}

const initialState: ChatState = {
  saving: false,
  fetching: false,
  saved: false,
  error: null,
  data: null,
  presaved: null,
  chats: null, // Initialize fetched chats as null
  agents: null,
  sending: false,
  lastSentMessage: null,
  errorCode: null,
};

const chatReducer = (
  state = initialState,
  action: ChatActionTypes
): ChatState => {
  switch (action.type) {
    case FETCH_CHATS:
      return { ...state, fetching: true, error: null, chats: null };
    case SAVE_CHAT:
      return { ...state, saving: true, saved: false, error: null };
    case SAVE_CHAT_SUCCESS:
      return { ...state, saving: false, saved: true, data: action.payload };
    case SAVE_CHAT_FAILURE:
      return { ...state, saving: false, error: action.payload };
    case FETCH_CHAT_BY_ID:
      return { ...state, fetching: true, error: null, data: null };
    case FETCH_CHAT_BY_ID_SUCCESS:
      return { ...state, fetching: false, presaved: action.payload };
    case FETCH_CHAT_BY_ID_FAILURE:
      return { ...state, fetching: false, error: action.payload };
    case FETCH_CHATS_SUCCESS: {
      console.log(action.payload);
      return {
        ...state,
        fetching: false,
        ...action.payload,
      };
    }

    case FETCH_CHATS_FAILURE:
      return { ...state, fetching: false, ...action.payload };
    case RESET_CHAT_SAVED:
      return { ...state, saved: false };
    case FETCH_AGENTS:
      return { ...state, fetching: true, error: null };
    case FETCH_AGENTS_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        fetching: false,
        errorCode: undefined,
        error: null,
        ...action.payload,
      };
    case FETCH_AGENTS_FAILURE:
      return { ...state, fetching: false, ...action.payload };
    case SEND_MESSAGE:
      return { ...state, sending: true, error: null };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        sending: false,
        lastSentMessage: action.payload,
        errorCode: undefined,
        error: null,
      };
    case SEND_MESSAGE_FAILURE:
      return { ...state, sending: false, error: action.payload };
    default:
      return state;
  }
};

export default chatReducer;

import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import loaderAnimation from "../assets/civicsync-lottie.json"; // Adjust the path accordingly

interface LoaderCivicSync {
  width?: string | number;
  height?: string | number;
  loop?: boolean;
  autoplay?: boolean;
  center?: boolean;
}

const LoaderCivicSync: React.FC<LoaderCivicSync> = ({
  width = 150,
  height = 150,
  loop = true,
  autoplay = true,
  center = false,
}) => {
  if (center) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <Player
          src={loaderAnimation}
          loop={loop}
          autoplay={autoplay}
          style={{ width, height }}
        />
      </div>
    );
  }
  return (
    <Player
      src={loaderAnimation}
      loop={loop}
      autoplay={autoplay}
      style={{ width, height }}
    />
  );
};

export default LoaderCivicSync;

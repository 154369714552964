import {
  FETCH_INSIGHTS,
  FETCH_INSIGHTS_SUCCESS,
  FETCH_INSIGHTS_FAILURE,
  EMPTY_INSIGHTS,
  InsightActionTypes,
  InsightData,
} from "../actions/insightActions";

import { AgentData } from "../actions/chatActions";

export interface InsightState {
  saving: boolean;
  fetching: boolean; // New field to track fetching state
  saved: boolean;
  error: string | null | undefined;
  errorCode: number | null | undefined;
  data: InsightData | null;
  insights: InsightData[] | null; // New field to store fetched questions
  agent: AgentData | null;
  usage: any[] | null;
}

const initialState: InsightState = {
  saving: false,
  fetching: false,
  saved: false,
  error: null,
  data: null,
  insights: [], // Initialize fetched questions as null
  usage: [],
  errorCode: null,
  agent: null,
};

const insightReducer = (
  state = initialState,
  action: InsightActionTypes
): InsightState => {
  switch (action.type) {
    case FETCH_INSIGHTS:
      return { ...state, fetching: true, error: null };
    case FETCH_INSIGHTS_SUCCESS: {
      // Create a new Set to track unique question IDs. Provide an empty array as a default if state.questions is null
      const uniqueInsightsIds = new Set(
        (state.insights || []).map((insight) => insight["_id"])
      );

      // Filter the new insights to include only those not already in the state
      const newInsight = (action.payload[0] || []).filter(
        (insight: InsightData) => !uniqueInsightsIds.has(insight["_id"])
      );

      const agentData: any = action.payload[1];
      if (!action.payload.length) {
        return {
          ...state,
          fetching: false,
          usage: action.payload,
          errorCode: null,
        };
      }
      console.log("agentData", action.payload);
      return {
        ...state,
        fetching: false,
        agent: agentData,
        errorCode: null,
        insights: [...(state.insights || []), ...newInsight],
      };
    }

    case EMPTY_INSIGHTS:
      return { ...state, insights: [], agent: null };

    case FETCH_INSIGHTS_FAILURE:
      return { ...state, fetching: false, ...action.payload };

    default:
      return state;
  }
};

export default insightReducer;

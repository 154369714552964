import React, { useEffect, useState, Suspense } from "react";

import { ComponentType } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch as useReduxDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "./redux/store";
import { Action } from "redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import { WebSocketProvider } from "./providers/WebSocketProvider";
import { fetchUserProfile } from "./redux/actions/authActions";
import "./App.css";
import Cookies from "js-cookie";
import { Toaster } from "./components/ui/toaster";
import LoaderCivicSync from "./components/LoaderCivicSync";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { CLIENT_ID, DOMAIN, API_AUDIENCE } from "./config";

// Lazy load the components
const HeroText = React.lazy(() => import("./components/HeroText")); // Default export
const AdminPanelLayout = React.lazy(
  () => import("./components/admin-panel/admin-panel-layout")
); // Default export
const ContentLayout = React.lazy(
  () => import("./components/admin-panel/content-layout")
); // Default export
const SurveyAnswers = React.lazy(() => import("./pages/SurveyAnswers")); // Default export
const AuthenticationForm = React.lazy(
  () => import("./pages/AuthenticationForm")
); // Default export
const HomeChat = React.lazy(() => import("./pages/HomeChat")); // Default export
const Settings = React.lazy(() => import("./pages/Settings")); // Default export
const Profile = React.lazy(() => import("./pages/Profile")); // Default export
const Poll = React.lazy(() => import("./pages/Poll")); // Default export
const Polls = React.lazy(() => import("./pages/Polls")); // Default export
const Profiles = React.lazy(() => import("./pages/Profiles")); // Default export
const NewPoll = React.lazy(() => import("./pages/NewPoll")); // Default export
const ManualPoll = React.lazy(() => import("./pages/ManualPoll")); // Default export
type AppDispatch = ThunkDispatch<RootState, void, Action<string>>;

function useDispatch() {
  return useReduxDispatch<AppDispatch>();
}

interface ProtectedComponentProps {
  component: ComponentType<any>;
  [key: string]: any; // To allow additional props
}

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({
  component,
  ...propsForComponent
}) => {
  const Cp = withAuthenticationRequired(component);
  return <Cp {...propsForComponent} />;
};

function AppWrapper() {
  return (
    <Router>
      <Auth0Provider
        domain={DOMAIN}
        clientId={CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: API_AUDIENCE,
        }}
      >
        <WebSocketProvider>
          <App />
        </WebSocketProvider>
      </Auth0Provider>
    </Router>
  );
}

const layout = Cookies.get("react-resizable-panels:layout");
const defaultLayout = layout ? JSON.parse(layout) : undefined;

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const [token, setToken] = useState("");

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken); // Update the state with the fetched token
      } catch (error) {
        console.error("Error fetching access token:", error);
        setToken(""); // Handle errors (e.g., by setting token to an empty string)
      }
    };

    if (!isLoading && isAuthenticated) {
      fetchToken();
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated && token) {
      dispatch(fetchUserProfile(token));
    }
  }, [token, isAuthenticated, dispatch]);

  useEffect(() => {
    if (isAuthenticated && token) {
      dispatch(fetchUserProfile(token));
      // Re-navigate to the current location to ensure the component loads correctly
      navigate(location.pathname, { replace: true });
    }
  }, [token, isAuthenticated, dispatch, location.pathname, navigate]);

  // Determine if the current location is the special forms page
  const isSpecialPage = location.pathname.startsWith("/forms/");

  return (
    <Suspense fallback={<LoaderCivicSync center={true} />}>
      {isSpecialPage ? (
        // Render the ManualPoll page without any layout
        <Routes>
          <Route path="/forms/:jobId/:secret" element={<ManualPoll />} />
        </Routes>
      ) : (
        // Render the regular layout with AdminPanel and ContentLayout
        <AdminPanelLayout>
          <ContentLayout title="">
            <Routes>
              <Route path="/" element={<NewPoll />} />
              <Route path="/ht" element={<HeroText />} />
              <Route path="/login" element={<AuthenticationForm />} />
              <Route
                path="/survey/:questionId"
                element={<ProtectedComponent component={SurveyAnswers} />}
              />
              <Route
                path="/chat/:chatId"
                element={<ProtectedComponent component={HomeChat} />}
              />
              <Route
                path="/settings"
                element={<ProtectedComponent component={Settings} />}
              />
              <Route
                path="/profiles/:agentId"
                element={<ProtectedComponent component={Profile} />}
              />
              <Route
                path="/polls/:jobId"
                element={<ProtectedComponent component={Poll} />}
              />
              <Route
                path="/polls"
                element={<ProtectedComponent component={Polls} />}
              />
              <Route
                path="/profiles"
                element={<ProtectedComponent component={Profiles} />}
              />
            </Routes>
          </ContentLayout>
          <Toaster />
        </AdminPanelLayout>
      )}
    </Suspense>
  );
}

export default AppWrapper;

import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { formatDistanceToNow, format } from "date-fns";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export const getDomainFromUrl = (url: string): string => {
  try {
    const newUrl = new URL(url);
    return newUrl.hostname;
  } catch (error) {
    console.error("Invalid URL", error);
    return "";
  }
};

export function formatTimestamp(timestamp: string) {
  // Parse the timestamp, ensuring it is in UTC format
  try {
    const zuluDate = new Date(
      timestamp[timestamp.length - 1] === "Z" ? timestamp : timestamp + "Z"
    );

    // Calculate the difference between the current date and the timestamp in milliseconds
    const now = new Date();
    const diffInMilliseconds = now.getTime() - zuluDate.getTime();

    // Calculate the difference in days
    const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

    // If the difference is more than 14 days (2 weeks), return the date in a human-readable format
    if (diffInDays > 14) {
      return format(zuluDate, "MMMM d, yyyy");
    }

    // Otherwise, return the relative time from now with a suffix
    return formatDistanceToNow(zuluDate, { addSuffix: true });
  } catch (error) {
    return "";
  }
}

export function formatNumberWithCommas(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
import axios from "axios";

import { AppThunk } from "../store";

import { BASE_API_URL } from "../../config";
import { useWebSocketContext } from "../../providers/WebSocketProvider";

export const SAVE_CHAT = "SAVE_CHAT";
export const UPDATE_CHAT = "UPDATE_CHAT";
export const SAVE_CHAT_SUCCESS = "SAVE_CHAT_SUCCESS";
export const SAVE_CHAT_FAILURE = "SAVE_CHAT_FAILURE";
export const FETCH_CHATS = "FETCH_CHATS";
export const FETCH_CHATS_SUCCESS = "FETCH_CHATS_SUCCESS";
export const FETCH_CHATS_FAILURE = "FETCH_CHATS_FAILURE";
export const RESET_CHAT_SAVED = "RESET_CHAT_SAVED";
export const FETCH_CHAT_BY_ID = "FETCH_CHAT_BY_ID";
export const FETCH_CHAT_BY_ID_SUCCESS = "FETCH_CHAT_BY_ID_SUCCESS";
export const FETCH_CHAT_BY_ID_FAILURE = "FETCH_CHAT_BY_ID_FAILURE";

export const FETCH_AGENTS = "FETCH_AGENTS";
export const FETCH_AGENTS_SUCCESS = "FETCH_AGENTS_SUCCESS";
export const FETCH_AGENTS_FAILURE = "FETCH_AGENTS_FAILURE";

// Action types for saving a message
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILURE = "SEND_MESSAGE_FAILURE";

// Define a type for the chat data
export interface ChatData {
  agent_id: any[];
  messages: any[];
  id: string;
  user_id: string;
  created_at: Date | undefined | null;
  updated_at: Date;
}

export interface NewChatData {
  agent_id: any[];
  messages: any[];
}

export interface AgentData {
  id: string;
  plugin_id: string;
  api_key: string;
  fullname: string;
  name: string;
  bio: string;
  email: string;
  year_of_birth: number;
  gender: string;
  race: string;
  citizenship: string;
  country_of_birth: string;
  country_of_residence: string;
  city_of_residence: string;
  education_level: string;
  household_income: string;
  employment_status: string;
  marital_status: string;
  interests: string;
  additional_info: string;
  vpn_active: boolean;
  currency_household_income: string;
}

interface SaveChatAction {
  type: typeof SAVE_CHAT;
}

interface UpdateChatAction {
  type: typeof UPDATE_CHAT;
}

interface SaveChatSuccessAction {
  type: typeof SAVE_CHAT_SUCCESS;
  payload: ChatData; // Use the specific type instead of 'any'
}

interface SaveChatFailureAction {
  type: typeof SAVE_CHAT_FAILURE;
  payload: string;
}

interface FetchChatsAction {
  type: typeof FETCH_CHATS;
}

interface FetchChatsSuccessAction {
  type: typeof FETCH_CHATS_SUCCESS;
  payload: ChatData[]; // It'll return an array of chats
}

export interface ChatError {
  errorCode: number | null | undefined;
  error: string;
}

interface FetchChatsFailureAction {
  type: typeof FETCH_CHATS_FAILURE;
  payload: ChatError;
}

interface ResetChatSavedAction {
  type: typeof RESET_CHAT_SAVED;
}

interface FetchChatByIdAction {
  type: typeof FETCH_CHAT_BY_ID;
}

interface FetchChatByIdSuccessAction {
  type: typeof FETCH_CHAT_BY_ID_SUCCESS;
  payload: ChatData;
}

interface FetchChatByIdFailureAction {
  type: typeof FETCH_CHAT_BY_ID_FAILURE;
  payload: string; // This represents an error message, adjust as necessary
}

interface FetchAgentsAction {
  type: typeof FETCH_AGENTS;
}

interface FetchAgentsSuccessAction {
  type: typeof FETCH_AGENTS_SUCCESS;
  payload: AgentData[]; // It'll return an array of chats
}

interface FetchAgentsFailureAction {
  type: typeof FETCH_AGENTS_FAILURE;
  payload: ChatError;
}

interface SendMessageAction {
  type: typeof SEND_MESSAGE;
}

interface SendMessageSuccessAction {
  type: typeof SEND_MESSAGE_SUCCESS;
  payload: any; // Adjust the payload type based on your data structure
}

interface SendMessageFailureAction {
  type: typeof SEND_MESSAGE_FAILURE;
  payload: string;
}

const chatUrl = `${BASE_API_URL}/chat/`;
const agentsUrl = `${BASE_API_URL}/agent/`;

export type ChatActionTypes =
  | SaveChatAction
  | UpdateChatAction
  | SaveChatSuccessAction
  | SaveChatFailureAction
  | FetchChatsAction
  | FetchChatsSuccessAction
  | FetchChatsFailureAction
  | ResetChatSavedAction
  | FetchChatByIdAction
  | FetchChatByIdSuccessAction
  | FetchChatByIdFailureAction
  | FetchAgentsAction
  | FetchAgentsSuccessAction
  | FetchAgentsFailureAction
  | SendMessageAction
  | SendMessageSuccessAction
  | SendMessageFailureAction;

export const saveChat =
  (data: NewChatData, token: string): AppThunk =>
  async (dispatch) => {
    dispatch({ type: SAVE_CHAT });

    try {
      const response = await axios.post(chatUrl, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: SAVE_CHAT_SUCCESS, payload: response.data.data });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.data) {
          // If the server responded with a specific error
          dispatch({
            type: SAVE_CHAT_FAILURE,
            payload: error.response.data.message,
          });
        } else {
          // If there's a generic error message from Axios
          dispatch({ type: SAVE_CHAT_FAILURE, payload: error.message });
        }
      } else {
        // If some other kind of error occurred
        dispatch({
          type: SAVE_CHAT_FAILURE,
          payload: "An unknown error occurred.",
        });
      }
    }
  };

export const updateChat =
  (data: ChatData): AppThunk =>
  async (dispatch) => {
    dispatch({ type: SAVE_CHAT });

    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(chatUrl + data?.id + "/", data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({ type: SAVE_CHAT_SUCCESS, payload: response.data.data[0] });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.data) {
          // If the server responded with a specific error
          dispatch({
            type: SAVE_CHAT_FAILURE,
            payload: error.response.data.message,
          });
        } else {
          // If there's a generic error message from Axios
          dispatch({ type: SAVE_CHAT_FAILURE, payload: error.message });
        }
      } else {
        // If some other kind of error occurred
        dispatch({
          type: SAVE_CHAT_FAILURE,
          payload: "An unknown error occurred.",
        });
      }
    }
  };

export const fetchChats =
  (
    page: number,
    token: string,
    limit: number,
    searchTerm: string,
    agentId: string,
    sort: string
  ): AppThunk =>
  async (dispatch) => {
    dispatch({ type: FETCH_CHATS });

    try {
      const params = {
        page,
        limit,
        ...(searchTerm && { search_term: searchTerm }),
        ...(agentId && { agent_id: agentId }),
        ...(sort && { sort }),
      };
      const response = await axios.get(`${chatUrl}`, {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });

      console.log("resss");
      console.log(response.data.data[0]);

      dispatch({ type: FETCH_CHATS_SUCCESS, payload: response.data.data[0] });
    } catch (error) {
      console.log("error", error);
      if (axios.isAxiosError(error)) {
        // Now TypeScript understands 'error' as an AxiosError
        const message = error.response?.data?.message || error.message;
        const status = error.response?.status;
        dispatch({
          type: FETCH_CHATS_FAILURE,
          payload: {
            errorCode: status,
            error: message,
          },
        });
      } else if (error instanceof Error) {
        // Handle generic errors
        dispatch({
          type: FETCH_CHATS_FAILURE,
          payload: {
            errorCode: null,
            error: error.message,
          },
        });
      } else {
        // Handle cases where the caught 'error' might not be an Error object
        dispatch({
          type: FETCH_CHATS_FAILURE,
          payload: {
            errorCode: null,
            error: "An unknown error occurred.",
          },
        });
      }
    }
  };

export const fetchChatById =
  (id: string, token: string): AppThunk =>
  async (dispatch) => {
    dispatch({ type: FETCH_CHAT_BY_ID });

    try {
      const response = await axios.get(`${chatUrl}${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: FETCH_CHAT_BY_ID_SUCCESS,
        payload: response.data.data[0],
      });
      return response.data;
    } catch (error) {
      let errorMessage =
        "An unexpected error occurred. Please try again later.";

      if (axios.isAxiosError(error)) {
        if (error.response) {
          if (error.response.status === 403) {
            errorMessage =
              "You do not have permission to access this resource.";
          } else {
            errorMessage = `Error: ${error.response.status} - ${
              error.response.data?.message || errorMessage
            }`;
          }
        } else {
          errorMessage = error.message;
        }
      }

      dispatch({
        type: FETCH_CHAT_BY_ID_FAILURE,
        payload: errorMessage,
      });
      return Promise.reject(errorMessage);
    }
  };

interface FetchAgentsParams {
  token: string;
  page?: number;
  limit?: number;
  search?: string;
  sortBy?: string;
  specificSearch?: { [key: string]: string }; // An object with field-value pairs
}

export const fetchAgents =
  ({
    token,
    page = 1,
    limit = 10,
    search = "",
    sortBy = "",
    specificSearch = {},
  }: FetchAgentsParams): AppThunk =>
  async (dispatch) => {
    console.log(specificSearch);
    dispatch({ type: FETCH_CHATS });
    try {
      // Prepare query parameters
      const params: { [key: string]: any } = {
        page,
        limit,
        search,
        sort_by: sortBy,
      };

      // Add specific search parameters if provided
      const specificSearchParams: string[] = [];
      Object.entries(specificSearch).forEach(([field, value]) => {
        specificSearchParams.push(`${field}:${value}`);
      });

      // Join specific search parameters into a single string
      if (specificSearchParams.length > 0) {
        params[`specific_search`] = specificSearchParams.join(",");
      }

      // Make the request
      const response = await axios.get(agentsUrl, {
        headers: { Authorization: `Bearer ${token}` },
        params, // Pass the query parameters
      });

      dispatch({ type: FETCH_AGENTS_SUCCESS, payload: response.data.data[0] });
      return response.data;
    } catch (error) {
      console.log("error", error);
      if (axios.isAxiosError(error)) {
        // Handle Axios errors
        const message = error.response?.data?.message || error.message;
        const status = error.response?.status;
        dispatch({
          type: FETCH_CHATS_FAILURE,
          payload: {
            errorCode: status,
            error: message,
          },
        });
      } else if (error instanceof Error) {
        // Handle generic errors
        dispatch({
          type: FETCH_CHATS_FAILURE,
          payload: {
            errorCode: null,
            error: error.message,
          },
        });
      } else {
        // Handle cases where the caught 'error' might not be an Error object
        dispatch({
          type: FETCH_CHATS_FAILURE,
          payload: {
            errorCode: null,
            error: "An unknown error occurred.",
          },
        });
      }
    }
  };

export const fetchAgentsById =
  ({ token, page = 1, limit = 10, search = "" }: FetchAgentsParams): AppThunk =>
  async (dispatch) => {
    dispatch({ type: FETCH_CHATS });
    try {
      // Prepare query parameters
      const params: { [key: string]: any } = {
        page,
        limit,
        search,
      };

      // Make the request
      const response = await axios.get(agentsUrl + "search-id", {
        headers: { Authorization: `Bearer ${token}` },
        params, // Pass the query parameters
      });

      dispatch({ type: FETCH_AGENTS_SUCCESS, payload: response.data.data[0] });
    } catch (error) {
      console.log("error", error);
      if (axios.isAxiosError(error)) {
        // Handle Axios errors
        const message = error.response?.data?.message || error.message;
        const status = error.response?.status;
        dispatch({
          type: FETCH_CHATS_FAILURE,
          payload: {
            errorCode: status,
            error: message,
          },
        });
      } else if (error instanceof Error) {
        // Handle generic errors
        dispatch({
          type: FETCH_CHATS_FAILURE,
          payload: {
            errorCode: null,
            error: error.message,
          },
        });
      } else {
        // Handle cases where the caught 'error' might not be an Error object
        dispatch({
          type: FETCH_CHATS_FAILURE,
          payload: {
            errorCode: null,
            error: "An unknown error occurred.",
          },
        });
      }
    }
  };

// export const sendMessage =
//   (messageData: any): AppThunk =>
//   async (dispatch) => {
//     console.log("messageData", messageData);
//     dispatch({ type: SEND_MESSAGE });

//     try {
//       const { socket, isConnected } = useWebSocketContext();

//       if (!isConnected || !socket) {
//         throw new Error("WebSocket is not connected");
//       }

//       console.log("Sending message:", messageData);
//       socket.send(JSON.stringify(messageData)); // Send message data as a JSON string
//       dispatch({ type: SEND_MESSAGE_SUCCESS, payload: messageData });
//     } catch (error: any) {
//       console.log("error", error);
//       dispatch({
//         type: SEND_MESSAGE_FAILURE,
//         payload: error.message || "An unknown error occurred.",
//       });
//     }
//   };

export const resetChatSaved = (): ResetChatSavedAction => ({
  type: RESET_CHAT_SAVED,
});

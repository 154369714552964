import axios from "axios";

import { AppThunk } from "../store";

import { BASE_API_URL } from "../../config";

export const SAVE_QUESTION = "SAVE_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const SAVE_QUESTION_SUCCESS = "SAVE_QUESTION_SUCCESS";
export const SAVE_QUESTION_FAILURE = "SAVE_QUESTION_FAILURE";
export const FETCH_QUESTIONS = "FETCH_QUESTIONS";
export const FETCH_QUESTIONS_SUCCESS = "FETCH_QUESTIONS_SUCCESS";
export const FETCH_QUESTIONS_FAILURE = "FETCH_QUESTIONS_FAILURE";
export const RESET_QUESTION_SAVED = "RESET_QUESTION_SAVED";
export const FETCH_QUESTION_BY_ID = "FETCH_QUESTION_BY_ID";
export const FETCH_QUESTION_BY_ID_SUCCESS = "FETCH_QUESTION_BY_ID_SUCCESS";
export const FETCH_QUESTION_BY_ID_FAILURE = "FETCH_QUESTION_BY_ID_FAILURE";
export const FETCH_QUESTION_RESPONSES = "FETCH_QUESTION_RESPONSES";

// Define a type for the question data
export interface QuestionData {
  [key: string]: any;
}

export interface NewQuestionData {
  question: string;
  answers: string[];
  demographics: string[];
  published: boolean;
  id: string;
}

export interface QuestionError {
  errorCode: number | null | undefined;
  error: string;
}

interface SaveQuestionAction {
  type: typeof SAVE_QUESTION;
}

interface UpdateQuestionAction {
  type: typeof UPDATE_QUESTION;
}

interface SaveQuestionSuccessAction {
  type: typeof SAVE_QUESTION_SUCCESS;
  payload: QuestionData; // Use the specific type instead of 'any'
}

interface SaveQuestionFailureAction {
  type: typeof SAVE_QUESTION_FAILURE;
  payload: string;
}

interface FetchQuestionsAction {
  type: typeof FETCH_QUESTIONS;
}

interface FetchQuestionsSuccessAction {
  type: typeof FETCH_QUESTIONS_SUCCESS;
  payload: QuestionData[]; // It'll return an array of questions
}

interface FetchQuestionsFailureAction {
  type: typeof FETCH_QUESTIONS_FAILURE;
  payload: QuestionError;
}

interface ResetQuestionSavedAction {
  type: typeof RESET_QUESTION_SAVED;
}

interface FetchQuestionByIdAction {
  type: typeof FETCH_QUESTION_BY_ID;
}

interface FetchQuestionByIdSuccessAction {
  type: typeof FETCH_QUESTION_BY_ID_SUCCESS;
  payload: QuestionData;
}

interface FetchQuestionByIdFailureAction {
  type: typeof FETCH_QUESTION_BY_ID_FAILURE;
  payload: QuestionError;
}

interface FetchQuestionResponsesAction {
  type: typeof FETCH_QUESTION_RESPONSES;
  payload: QuestionData;
}

const questionUrl = `${BASE_API_URL}/v1`;

export type QuestionActionTypes =
  | SaveQuestionAction
  | UpdateQuestionAction
  | SaveQuestionSuccessAction
  | SaveQuestionFailureAction
  | FetchQuestionsAction
  | FetchQuestionsSuccessAction
  | FetchQuestionsFailureAction
  | ResetQuestionSavedAction
  | FetchQuestionByIdAction
  | FetchQuestionByIdSuccessAction
  | FetchQuestionByIdFailureAction;

export const saveQuestion =
  (query: string, agentIds: string[], token: string): AppThunk =>
  async (dispatch) => {
    dispatch({ type: SAVE_QUESTION });

    try {
      const data = {
        query,
        agent_id: agentIds,
      };

      const response = await axios.post(
        questionUrl + "/submit-query-by-agents",
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      dispatch({ type: SAVE_QUESTION_SUCCESS, payload: response.data.data[0] });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.data) {
          // If the server responded with a specific error
          dispatch({
            type: SAVE_QUESTION_FAILURE,
            payload: error.response.data.detail,
          });
        } else {
          // If there's a generic error message from Axios
          dispatch({ type: SAVE_QUESTION_FAILURE, payload: error.message });
        }
      } else {
        // If some other kind of error occurred
        dispatch({
          type: SAVE_QUESTION_FAILURE,
          payload: "An unknown error occurred.",
        });
      }
    }
  };

export const submitManualPoll =
  (id: string, agentIds: string[], token: string): AppThunk =>
  async (dispatch) => {
    dispatch({ type: FETCH_QUESTION_BY_ID });

    try {
      const data = {
        job_id: id,
        agent_id: agentIds,
      };

      const response = await axios.post(
        questionUrl + "/submit-manual-poll",
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      dispatch({
        type: FETCH_QUESTION_BY_ID_SUCCESS,
        payload: response.data.data[0],
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.data) {
          // If the server responded with a specific error
          dispatch({
            type: FETCH_QUESTION_BY_ID_FAILURE,
            payload: {
              errorCode: error.response.status,
              error: error.message,
            },
          });
        } else {
          // If there's a generic error message from Axios
          dispatch({
            type: FETCH_QUESTION_BY_ID_FAILURE,
            payload: {
              errorCode: null,
              error: error.message,
            },
          });
        }
      } else {
        // If some other kind of error occurred
        dispatch({
          type: FETCH_QUESTION_BY_ID_FAILURE,
          payload: {
            errorCode: null,
            error: "An unknown error occurred.",
          },
        });
      }
    }
  };

export const updateQuestion =
  (data: NewQuestionData): AppThunk =>
  async (dispatch) => {
    dispatch({ type: SAVE_QUESTION });

    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(questionUrl + "/" + data?.id, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: FETCH_QUESTION_BY_ID_SUCCESS,
        payload: response.data.data[0],
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.data) {
          // If the server responded with a specific error
          dispatch({
            type: SAVE_QUESTION_FAILURE,
            payload: error.response.data.message,
          });
        } else {
          // If there's a generic error message from Axios
          dispatch({ type: SAVE_QUESTION_FAILURE, payload: error.message });
        }
      } else {
        // If some other kind of error occurred
        dispatch({
          type: SAVE_QUESTION_FAILURE,
          payload: "An unknown error occurred.",
        });
      }
    }
  };

export const fetchQuestions =
  (
    page: number,
    token: string,
    limit: number = 10,
    searchTerm?: string,
    sort?: string
  ): AppThunk =>
  async (dispatch) => {
    dispatch({ type: FETCH_QUESTIONS });

    try {
      const params = {
        page,
        limit,
        ...(searchTerm && { search_term: searchTerm }),
        ...(sort && { sort }),
      };

      const response = await axios.get(questionUrl + "/polls", {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });

      dispatch({
        type: FETCH_QUESTIONS_SUCCESS,
        payload: response.data.data, // Assuming the API returns an array of data
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.data) {
          // If the server responded with a specific error
          dispatch({
            type: FETCH_QUESTIONS_FAILURE,
            payload: {
              errorCode: error.response.status,
              error: error.message,
            },
          });
        } else {
          // If there's a generic error message from Axios
          dispatch({
            type: FETCH_QUESTIONS_FAILURE,
            payload: {
              errorCode: null,
              error: error.message,
            },
          });
        }
      } else {
        // If some other kind of error occurred
        dispatch({
          type: FETCH_QUESTIONS_FAILURE,
          payload: {
            errorCode: null,
            error: "An unknown error occurred.",
          },
        });
      }
    }
  };

export const fetchQuestionById =
  (id: string, token: string): AppThunk =>
  async (dispatch) => {
    dispatch({ type: FETCH_QUESTION_BY_ID });

    try {
      const response = await axios.get(questionUrl + "/poll?job_id=" + id, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: FETCH_QUESTION_BY_ID_SUCCESS,
        payload: response.data.data[0],
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.data) {
          // If the server responded with a specific error
          dispatch({
            type: FETCH_QUESTION_BY_ID_FAILURE,
            payload: {
              errorCode: error.response.status,
              error: error.message,
            },
          });
        } else {
          // If there's a generic error message from Axios
          dispatch({
            type: FETCH_QUESTION_BY_ID_FAILURE,
            payload: {
              errorCode: null,
              error: error.message,
            },
          });
        }
      } else {
        // If some other kind of error occurred
        dispatch({
          type: FETCH_QUESTION_BY_ID_FAILURE,
          payload: {
            errorCode: null,
            error: "An unknown error occurred.",
          },
        });
      }
    }
  };

export const resetQuestionSaved = (): ResetQuestionSavedAction => ({
  type: RESET_QUESTION_SAVED,
});

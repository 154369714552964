import {
  SAVE_QUESTION,
  SAVE_QUESTION_SUCCESS,
  SAVE_QUESTION_FAILURE,
  FETCH_QUESTIONS,
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_FAILURE,
  RESET_QUESTION_SAVED,
  FETCH_QUESTION_BY_ID,
  FETCH_QUESTION_BY_ID_SUCCESS,
  FETCH_QUESTION_BY_ID_FAILURE,
  FETCH_QUESTION_RESPONSES,
  QuestionActionTypes,
  QuestionData,
} from "../actions/questionActions";

export interface QuestionState {
  saving: boolean;
  fetching: boolean; // New field to track fetching state
  saved: boolean;
  error: string | null | undefined;
  errorCode: number | null | undefined;
  data: QuestionData | null;
  job_id: string | null;
  questions: QuestionData[] | null; // New field to store fetched questions
}

const initialState: QuestionState = {
  saving: false,
  fetching: false,
  saved: false,
  error: null,
  data: null,
  job_id: null,
  questions: null, // Initialize fetched questions as null
  errorCode: null,
};

const questionReducer = (
  state = initialState,
  action: QuestionActionTypes
): QuestionState => {
  switch (action.type) {
    case SAVE_QUESTION:
      return { ...state, saving: true, saved: false, error: null };
    case SAVE_QUESTION_SUCCESS:
      return {
        ...state,
        saving: false,
        saved: true,
        errorCode: undefined,
        error: null,
        ...action.payload,
      };
    case SAVE_QUESTION_FAILURE:
      return { ...state, saving: false, error: action.payload };
    case FETCH_QUESTION_BY_ID:
      return { ...state, fetching: true, error: null };
    case FETCH_QUESTION_BY_ID_SUCCESS:
      return {
        ...state,
        fetching: false,
        errorCode: undefined,
        error: null,
        data: action.payload,
      };
    case FETCH_QUESTION_BY_ID_FAILURE:
      return { ...state, fetching: false, ...action.payload };
    case FETCH_QUESTIONS:
      return { ...state, fetching: true, error: null };
    case FETCH_QUESTIONS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        questions: action.payload,
      };
    }

    case FETCH_QUESTIONS_FAILURE:
      return { ...state, fetching: false, ...action.payload };
    case RESET_QUESTION_SAVED:
      return { ...state, saved: false };

    default:
      return state;
  }
};

export default questionReducer;
